<template lang="pug">
section.league(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-main
    transition(name="page", mode="out-in")
      router-view(:key="$route.fullPath")
  custom-footer
</template>

<script>
import CustomFooter from "@/components/common/Footer";

export default {
  name: "League",
  components: {
    CustomFooter,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
.section.league {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}
</style>
